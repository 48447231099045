/* ================= Forgote PAssword =============== */

.forgotePassword {
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputCard {
    width: 100%;
    max-width: 350px;
}

.inputCard p {
    color: #808080;
}

.imageDiv {
    max-width: 500px;
    margin: auto;
}

.imageDiv img {
    width: 100%;
    height: 100%;
}

.otp-input {
    text-align: center;
    width: 50px;
    height: 40px;
    margin-right: 10px;
}

.toggle-visibility {
    background-color: transparent;
    border: unset;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.resend-button {
    background-color: transparent;
    border: unset;
    text-decoration: underline;
}

.inputCard a {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-family: var(---font02);
}

.inputCard a svg {
    margin: 0px 5px 0px 0px;
    font-size: 20px;
}

.emptyClick {
    width: 100%;
    height: 100%;
}

.dataGet {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.badge.success {
    background: green;
}

.badge.pending {
    background: orange;
}

.badge.failed {
    background: red;
}