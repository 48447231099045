@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');

.new-home-banner-section {
    /* border: 1px solid blue; */
    position: relative;
    height: 86vh;
    background: url("../Assert/banner/kataria\ banner\ new.png");
    background-size: 100% 100%;
}

.new-home-banner-main-con {
    /* border: 1px solid red; */
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}


.new-home-banner-main-con-text-main-con {
    /* border: 1px solid red; */
}

.new-home-banner-main-con-text-main-con h2 {
    font-family: var(---font04);
    text-transform: uppercase;
    font-size: 110px;
    color: #2d3192;
    font-weight: bolder;
    margin: 0px;
    text-align: center;
}

.new-home-banner-main-con-text-main-con h3 {
    text-align: center;
    font-family: var(---font02);
    font-size: 60px;
    margin: 0px;
}

.new-home-banner-main-con-text-para-main-con {}

.new-home-banner-main-con-text-para-main-con p {
    font-family: "Dancing Script", cursive;
    text-align: center;
    font-size: 35px;
    font-weight: 900;
    margin: 0px;
    line-height: normal;
    letter-spacing: 2px;
}

.new-home-banner-main-con-btn-main-con {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px 0px 0px;
}

.new-home-banner-main-con-btn-main-con button {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    color: white;
    background: #2d3192;
    border-radius: 50px;
    font-size: 25px;
}

.new-home-banner-main-con-btn-main-con button svg {
    margin: 0px 6px 0px 0px;
    font-size: 25px;
}

.new-home-banner-section:before {
    content: '';
    position: absolute;
    width: 30%;
    height: 100%;
    background: #faf5f2;
    z-index: -11;
    top: 0;
    left: 0;
}

.new-home-banner-section::after {
    content: '';
    position: absolute;
    width: 30%;
    height: 100%;
    background: #faf5f2;
    z-index: -11;
    top: 0;
    right: 0;
}


.new-banner-image-main-con {
    /* border: 1px solid black; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 86vh;
}

.new-banner-image-con {
    /* border: 1px solid green; */
    height: 80vh;
}

.new-banner-image-con img {
    height: 100%;
    width: 100%;
}

.new-banner-details-main-con {
    /* border: 1px solid red; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-banner-details-con {
    text-align: center;
}

.new-banner-details-con h2 {
    font-family: var(---font02);
    font-size: 45px;
    margin: 0px;
    color: var(---colorBlue);
}

.new-banner-details-con h3 {
    margin: 10px 0px 0px 0px;
    font-family: var(---stylefont);
    font-size: 35px;
    color: var(---colorRed);
}

.new-banner-details-con p {
    margin: 10px 0px 0px 0px;
    font-family: var(---font01);
    color: #646464;
    font-size: 16px;
}

.new-banner-details-btn-con {
    margin: 20px 0px 0px 0px;
}

.new-banner-details-btn-con button {
    border: 2px solid var(---colorBlue);
    padding: 10px 20px;
    border-radius: 30px;
    color: var(---colorBlue);
}



@media(max-width:1300px) {
    .new-home-banner-main-con-text-main-con h2 {
        font-size: 70px !important;
    }
    .new-home-banner-main-con-text-main-con h3 {
        font-size: 50px;
    }
}

@media(max-width:1024px) {
    .new-home-banner-main-con-text-main-con h2 {
        font-size: 70px !important;
    }
    .new-home-banner-main-con-text-main-con h3 {
        font-size: 50px;
    }
    .new-home-banner-section {
        /* border: 1px solid blue; */
        position: relative;
        height: 75vh;
    }
}

@media(max-width:768px) {
    .new-banner-details-con h2 {
        font-size: 30px;
    }

    .new-banner-details-con h3 {
        margin: 5px 0px 0px 0px;
        font-family: var(---stylefont);
        font-size: 20px;
        color: var(---colorRed);
    }

    .new-banner-details-con p {
        margin: 10px 0px 0px 0px;
        font-family: var(---font01);
        color: #646464;
        font-size: 10px;
    }

    .new-home-banner-section {
        /* border: 1px solid blue; */
        position: relative;
        height: 60vh;
    }

    .new-banner-image-main-con {
        height: 60vh;
    }

    .new-banner-image-con {
        /* border: 1px solid green; */
        height: 45vh;
    }
    .new-home-banner-main-con-text-main-con h2 {
        font-size: 50px !important;
    }
    .new-home-banner-main-con-text-main-con h3 {
        font-size: 30px;
    }
    .new-home-banner-main-con-text-para-main-con p {
        font-family: "Dancing Script", cursive;
        text-align: center;
        font-size: 22px;
        font-weight: 900;
        margin: 0px;
        line-height: normal;
        letter-spacing: 0px;
    }
    .new-home-banner-main-con-btn-main-con button {
           font-size: 15px;
    }
    .new-home-banner-main-con-btn-main-con button svg {
        margin: 0px 6px 0px 0px;
        font-size: 17px;
    }
}


@media(max-width:576px) {
    .new-banner-image-con {
        /* border: 1px solid green; */
        height: 35vh;
    }

    .new-banner-details-con h2 {
        font-size: 18px;
    }

    .new-banner-details-btn-con button {
        border: 2px solid var(---colorBlue);
        padding: 6px 14px;
        border-radius: 30px;
        color: var(---colorBlue);
        font-size: 10px;
    }

    .new-banner-details-btn-con {
        margin: 10px 0px 0px 0px;
    }

    .new-banner-details-con p {
        margin: 5px 0px 0px 0px;
    }

    .new-banner-image-main-con {
        height: 45vh;
    }

    .new-home-banner-section {
        /* border: 1px solid blue; */
        position: relative;
        height: 215px;
    }

    .new-home-banner-main-con-text-main-con h2 {
        font-size: 35px !important;
    }
    .new-home-banner-main-con-text-main-con h3 {
        font-size: 20px;
    }
    .new-home-banner-main-con-text-para-main-con p {
        font-size: 15px;
    }
    .new-home-banner-main-con-btn-main-con {
        margin: 10px 0px 0px 0px;
    }
    .new-home-banner-main-con-btn-main-con button svg {
        font-size: 15px;
    }

    .new-home-banner-main-con-btn-main-con button {
        display: flex;
        align-items: center;
        padding: 8px 13px;
    }
}


@media(max-width:430px) {
    .new-home-banner-section {
        /* border: 1px solid blue; */
        position: relative;
        height: 30vh;
    }

    .new-banner-image-main-con {
        height: 35vh;
    }

    .new-banner-image-con {
        /* border: 1px solid green; */
        height: 30vh;
    }

    .new-banner-details-btn-con button {
        border: 1px solid var(---colorBlue);
        padding: 3px 9px;
        border-radius: 25px;
        color: var(---colorBlue);
        font-size: 7px;
    }
    .new-home-banner-main-con-text-main-con h2 {
        font-size: 25px !important;
    }
    .new-home-banner-main-con-text-main-con h3 {
        font-size: 17px;
    }
    .new-home-banner-main-con-btn-main-con button {
        font-size: 8px;
    }
    .new-home-banner-main-con-btn-main-con button svg {
        font-size: 11px;
    }
    .new-home-banner-main-con-btn-main-con button {
        display: flex;
        align-items: center;
        padding: 7px 10px;
    }
}

@media(max-width:380px) {
    .new-home-banner-section {
        /* border: 1px solid blue; */
        position: relative;
        height: 27vh;
    }

    .new-banner-image-main-con {
        height: 27vh;
    }

    .new-banner-image-con {
        /* border: 1px solid green; */
        height: 25vh;
    }
}