.CheckOutPage-Section-1 {
    /* border: 1px solid red; */
    height: 40vh;
    background: whitesmoke;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url(https://www.tasva.com/cdn/shop/collections/5-Mobile-banner-1500wx500h_f170219d-e854-437d-a101-c5360353de67.jpg?v=1660651543&width=2048);

    -webkit-background-size: cover;
}

.CheckOutPage-Section-con {
    /* border: 1px solid blue; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 40vh;
}

.CheckOutPage-Section-Text {
    margin: 0px 0px 0px 30px;
}

.CheckOutPage-Section-NavPage {
    display: flex;
    align-items: center;
    font-family: var(---SmallFont);
}

.CheckOutPage-Section-Text h2 {
    text-align: left;
    text-transform: capitalize;
    font-family: var(---MainHeadFont);
    font-weight: 500;
    font-size: 45px;
    letter-spacing: 1px;
    color: black;
}

.CheckOutPage-Section-NavPage span {
    font-family: var(---Font1);
    color: black;
    cursor: pointer;
        display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
}

.CheckOutPage-Section-NavPage span svg {
    font-size: 17px;
    margin: 0px 4px 5px 0px;
}

.CheckOutPage-Section-NavPage h3 {}

.CheckOutPage-Section-NavPage h3 svg {
    color: black;
}

.CheckOutPage-Section-NavPage h2 {
    font-size: 16px;
    margin: 0px;
    font-family: var(---Font1);
    font-weight: normal;
    color: var(---SmallColor);
}

/* ////////////////////// End /////////////////////////////// */

.CheckOutPage-Section-2{
    /* border: 1px solid blue; */
    padding: 10vh;
}

@media(max-width:768px){
    .CheckOutPage-Section-2 {
        /* border: 1px solid blue; */
        padding: 10vh 0px;
    }
}

@media(max-width:430px){
    .Home-Section3-Card-Image {
        /* border: 1px solid red; */
        height: 30vh;
        width: 30vh;
        width: auto;
        height: auto;
        background-color: var(--tp-grey-1);
        background-image: radial-gradient(95.56% 95.56% at 50% 50%, #FFFFFF 0%, #BDDEFF 100%);
        /* border-radius: 50%; */
        text-align: center;
        /* display: flex; */
        /* align-items: center; */
        /* justify-content: center; */
        /* margin: auto; */
        padding: 10px;
    }

    .Home-Section3-Card-Image img {
        height: 100%;
        width: 100%;
        transition: transform 0.3s ease-in-out;
    }
}