.filter-sort-main{
    display: flex;
    border: 2px solid black;
    border-radius: 9999px;
    text-align: center;
    justify-content: space-around;
    background-color: var(--primaryColor);
    color: black;
    padding: 8px 8px;
}
.filter-float{
    /* width: 100%; */
    /* text-align: center; */
    /* border-right: 1px solid #000; */
}
.filter-sort-divider{
    width: 3px;
    border-right: 1px solid black;
    z-index: 100;
}
.filter-sort-section{
    position: sticky;
    bottom: 18px;
}