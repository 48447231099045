.Contact-Section-1 {
    /* border: 1px solid red; */
    height: 40vh;
    background: linear-gradient(90deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url("../Assert/Banner-4.jpg");
    -webkit-background-size: cover;
    /* background: linear-gradient(40deg, rgb(38, 51, 215, 3), #EC0411); */
}

.Contact-Section-con {
    /* border: 1px solid blue; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 40vh;
}

.Contact-Section-Text {
    margin: 0px 0px 0px 30px;
}

.Contact-Section-NavPage {
    display: flex;
    align-items: center;
    font-family: var(---SmallFont);
}

.Contact-Section-Text h2 {
    text-align: left;
    text-transform: capitalize;
    font-family: var(---MainHeadFont);
    font-weight: 500;
    font-size: 30px;
    letter-spacing: 1px;
    color: white;
}

.Contact-Section-NavPage span {
    font-family: var(---Font1);
    color: white;
}

.Contact-Section-NavPage span svg {
    font-size: 17px;
    margin: 0px 4px 5px 0px;
}

.Contact-Section-NavPage h3 {}

.Contact-Section-NavPage h3 svg {
    color: white;
}

.Contact-Section-NavPage h2 {
    font-size: 16px;
    margin: 0px;
    font-family: var(---Font1);
    font-weight: normal;
    color: white;

}

/* ////////////////////// End /////////////////////////////// */

.Contact-Section-2 {
    /* border: 1px solid red; */
    padding: 10vh 0px;
}

.Contact-Section-2-con {
    /* border: 1px solid blue; */
    box-shadow: 0 30px 50px #052f281f;
    padding: 30px;
}

.Contact-Section-2-Heading {
    /* border: 1px solid green; */
    text-align: left;
}

.Contact-Section-2-Heading h2 {
    font-size: 25px;
    font-family: var(---font01);
    margin: 0px;
}

.Contact-page-Section-form {
    /* border: 1px solid blue; */
    margin-top: 20px;
}

.Contact-page-Section-form-Inputs {
    /* border: 1px solid greenyellow; */
    text-align: left;
    margin: 20px 0px 0px 0px;
}

.Contact-page-Section-form-Inputs .form-group label {
    font-family: var(---font01);
    margin: 0px 0px 3px 3px;
}

.Contact-page-Section-form-Inputs .form-group input {}

.Contact-page-Section-form-Inputs .form-group textarea {
    height: 20vh;
}

.Contact-page-Section-form-btn {
    margin: 20px 0px 0px 0px;
}

.Contact-page-Section-form-btn button {
    font-size: 17px;
    padding: 0px 20px 0px 20px;
    font-weight: 500;
    background-color: black;
    color: white;
    font-family: var(---font01);
    border: 2px solid black;
}

:where(.css-dev-only-do-not-override-zl9ks2).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: white;
    border-color: black !important;
}


/* contact details  */

.Contact-Page-Details-con {
    /* border: 1px solid blue; */
}

.Contact-Page-Icons-Info {
    /* border: 1px solid red; */
    margin: 20px 0px 0px 0px;
}

.Contact-Page-Icons {
    /* border: 1px solid black; */
    width: 60px;
    height: 60px;
}

.Contact-Page-Icons img {
    height: 100%;
    width: 100%;
}

.Contact-Page-Info {
    /* border: 1px solid hotpink; */
    margin: 15px 0px 0px 0px;
    text-align: left;
}

.Contact-Page-Info h2 {
    font-size: 17px;
    font-family: var(---font02);
    /* margin: 0px; */
}

.Contact-Page-Info h2 a {
    text-decoration: none;
    color: black;
}

.Contact-Page-Info h3 {
    font-size: 17px;
    font-family: var(---font02);
    /* margin: 0px; */
}

.Contact-Page-Info h2 a {}

.Contact-Page-Info h3 a {
    text-decoration: none;
    color: black;
}

.Contact-Page-Info {
    /* border: 1px solid red; */
}

.ContactPage-Social-Media {}

.ContactPage-Social-Media span a {
    text-decoration: none;
    color: black;
}

.ContactPage-Social-Media span a svg {
    font-size: 40px;
    margin: 10px;
    border: 1px solid #eeeeee;
    padding: 10px;
}

:where(.css-dev-only-do-not-override-zl9ks2).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: black !important;
}

@media(max-width:768px){

    .Home-Section3-Card-Image {
        height: 100%;
        width: 100%;
    }
    .Home-Section3-Card-Image img {
        height: 100%;
        width: 100%;
        transition: transform 0.3s ease-in-out;
    }
}
.google-map{
    height: 300px !important;
}

@media(max-width:576px){
    .about-us-new-Section-1 {
        height: 20vh;
        }
        .Contact-Section-con {
            height: 20vh;
        }
        .Contact-Section-1 {
            /* border: 1px solid red; */
            height: 20vh;
        }
}