.section-icons-main{
    /* border: 1px solid blue; */
    padding: 10vh 0px;
    background-color: #faf5f2;
}

.section-icons-main-details-con{
    /* border: 1px solid red; */
}

.section-icons-details-main-con{
    /* border: 1px solid  green; */
}

.section-icons-details-main-con-images{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.section-icons-details-con-images{
    /* border: 1px solid red; */
    height: 60px;
    width: auto;
}

.section-icons-details-con-images img{
    height: 100%;
    width: auto;
}

.section-icons-details-info-main-con{

}

.section-icons-details-info-main-con h3{
text-align: center;
font-family: var(---font03);
font-size: 14px;
text-transform: uppercase;
}


@media(max-width:576px){
    .section-icons-details-con-images {
        height: 40px;
        width: auto;
    }

    .section-icons-details-info-main-con h3 {
        text-align: center;
        font-family: var(---font03);
        font-size: 10px;
        text-transform: uppercase;
    }
}