.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7); /* semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
    /* transition: opacity 0.5s ease;  */
    opacity: 1; /* Initially set opacity to 1 */
  }
  
  .loader img {
    width: 100px; /* Adjust the width and height as per your loader image */
    height: 20px;
  }
  
  .content {
    opacity: 1;
    transition: opacity 0.5s ease; /* Add a transition for opacity */
  }
  
  .content.loading {
    opacity: 0; /* Set opacity to 0 when loading */
  }
  .loader img {
    animation: loader 3s linear;
  }
  
  @keyframes loader {
    from {
      transform: scale(4);
    }
  
    to {
      transform: scale(0);
    }
  }