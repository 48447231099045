.customer-review-card{
    display: flex;
    justify-content: start;
    align-items: baseline !important;
    gap: 5px
}
.customer-review-card-start{
    background-color: #eee;
    padding: 10px 35px;
    /* width: max-content; */
    border-radius: 6px;
}

.customer-verified{
    color: rgb(25, 123, 255);
}
/* .swiper-wrapper{
    padding-bottom: 35px;
} */
.user-googleReview-div{
    position: relative;
}
.user-googleReview-img{
    position: absolute;
    right: 0;
    bottom: -8px;
    left: 15px;
    height: 16px;

}

.customer-review-card h6{
    margin-left: 15px;
}

.testimonials.grid .item {
    margin: 10px 20px;
    position: relative;
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
}
.testimonials.grid .item .cont {
    border: 10px solid #f7f7f7;
    padding: 25px 15px 0;
    position: relative;
}

.testimonials.grid .item .cont p {
    color: #333;
    font-family: var(---font03);
    font-size: 14px;
    font-weight: 300;
}

.section-head p {
    font-family: var(---paracolor);
    font-size: 17px;
    font-weight: 400;
    text-align: left;
}

.testimonials.grid .item .info {
    margin-top: 30px;
    padding-left: 30px;
    position: relative;
}

.testimonials.grid .item .info:after {
    border-right: 30px solid transparent;
    border-top: 25px solid #f7f7f7;
    content: "";
    height: 0;
    left: 100px;
    position: absolute;
    top: -30px;
    width: 0;
}


@media(max-width:576px){
    .testimonials.grid .item {
        margin: 0px;
    }
}