.OrderAddresscon {
    /* border: 1px solid blue; */
    margin: 30px 0px 0px 0px;
}

.OrderAddresscon-personal-details {
    text-align: left;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    margin-top: 15px;
    box-shadow: 0 0px 1px #052f281f;
    border: 1px solid #f1f1f1;
}

.OrderAddresscon-personal-details input {
    margin: 0px 10px 0px 0px;
}

.OrderAddresscon-personal-deatails-use {
    margin: 20px 0px 0px 0px;
}

.OrderAddresscon-personal-deatails-use button {
    border: 1px solid green;
    background-color: black;
    color: white;
    border: 1px solid black;
    padding: 10px 20px;
}

.OrderDetials-billing-personal-detailsOrderAddresscon {
    /* border: 1px solid #E0E2E3; */
    padding: 0px 20px 20px 20px;
    border-radius: 8px;
    margin: 15px 0px 0px 0px;
}

.OrderAddresscon-personal-details label {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    font-size: 14px;
    overflow: hidden;
}

.OrderDetials-billing-personal-details {
    /* border: 1px solid red; */

}

.OrderAddresscon-personal-deatails {
    text-align: left;
    margin: 20px 0px 0px 0px;
}



.OrderAddresscon-personal-deatails button {
    color: white !important;
    background-color: black;
    border: 2px solid black;
    padding: 0px 20px !important;
    border-radius: unset;
    margin: 0px 10px 0px 0px;
}

.OrderAddresscon-personal-deatails :where(.css-dev-only-do-not-override-2i2tap).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    color: white;
    background-color: transparent;
}

.OrderAddresscon-personal-deatails button svg {
    font-size: 20px;
    margin: 0px 8px 0px 0px;
}

:where(.css-dev-only-do-not-override-zl9ks2).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    background: black !important;
}

@media(max-width:430px){
    .OrderAddresscon-personal-deatails button:nth-child(2){
        margin: 0px 0px 15px 0px;

    }
    
}