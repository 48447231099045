.clothCard-product-title {
  font-weight: 400;
  font-family: var(---font03);
  font-size: 13px;
  margin: 0px 0px 15px 0px !important;
  text-transform: uppercase;
}

.main-card-product {
  position: relative !important;
  background-repeat: no-repeat;
  height: 400px;
  border-radius: 10px;
  box-shadow: 0 0 5px #bababa;

}

.new-tag{
  position: absolute;
  right: 15px;
  top: 15px;
}

.new-tag h3{
  font-size: 15px;
  background: green;
  color: white;
  padding: 4px 10px;
  border-radius: 3px;
  font-family: var(---font01);
}

.card-product-shop-now {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: rgb(255, 255, 255, 0.7);
  transform: translateY(26px);
  opacity: 0;
  padding: 10px 0px;
  font-family: var(---font01);
  font-size: 17px;
  color: var(---colorBlue) !important;
}

.main-card-product:hover a {
  z-index: 1000;
  transform: translateY(0px);
  opacity: 1;
}

.clothCard-product-deatils-main-con {
  /* border: 1px solid blue; */
  margin: 15px 0px;
  text-align: center;
}

.clothCard-product-deatils-main-con p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  font-size: 13px;
  overflow: hidden;
  margin: 5px 0px 15px 0px;
}

.clothCard-product-deatils-main-con-mainprice label {
  color: red;
}

.clothCard-product-deatils-main-con-price label {
  font-family: var(---font03);
  font-size: 13px;
  letter-spacing: 2px;
}

.clothCard-product-deatils-main-con-price {
  margin: 10px 0px 0px 0px;
  display: flex;
  justify-content: space-evenly;
}

.clothCard-product-deatils-main-con-offerprice label {
  position: relative;
}

.clothCard-product-deatils-main-con-offerprice label::after {
  position: absolute;
  content: "";
  top: 45%;
  left: 0;
  height: 1px;
  width: 100%;
  background: black;
}




/* ///////// */

.product-card-main-con {
  /* border: 1px solid blue; */
}

.product-card-main-con a {
  text-decoration: none;

}

.product-card-Image-con {
  /* border: 1px solid green; */
  height: 65vh;
  position: relative;
}

.product-card-Image-con a {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.product-item__bg {
  /* border: 1px solid blue; */
  display: block;
  overflow: hidden;
  background-size: cover;
  background-position: 50%;
  font-size: 0;
  line-height: 1;
  will-change: transform, opacity;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

/* image 2  */

.product-item__bg__under {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
  font-size: 0;
  line-height: 1;
  overflow: hidden;
  transition: opacity .25s ease-in-out;
}

.double__image .product-item__bg__under {
  opacity: 0;
}

.double__image:hover .product-item__bg__under {
  opacity: 1;
}


/* // */

.product-item__image {}

.product-item__image:hover .slideup>button {
  opacity: 100;
}

.product-item__image .slideup>button {
  opacity: 0;
}

.slideup>button {
  position: relative;
  text-decoration: none;
  display: block;
  width: 100%;
  color: var(---color-text);
  padding: 6px;
  cursor: pointer;
  text-align: center;
  background: none;
  border: none;
  box-shadow: none;
  backdrop-filter: blur(10px);
  transition: all .3s;
}

.product-item__image:hover .slideup {
  bottom: 0;
}

.product-item__image .slideup {
  position: absolute;
  text-align: center;
  transition: all .4s ease-in-out;
}

.slideup {
  position: absolute;
  bottom: -60px;
  transition: bottom .25s ease-in;
  z-index: 9;
  width: 100%;
  background: #fff0;
  border-top: none;
}

.slideup button span {
  text-transform: uppercase;
  letter-spacing: .12em;
  font-family: var(---font01);
  font-size: 12px;
}


.slideup>button:after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  position: absolute;
  background: #ffffff;
  opacity: .7;
}

/* /// */

/* card deatils  */

.Product-card-details-con {
  display: block;
  height: 100%;
  color: #212121;
  padding: 20px;
}

.Product-card-details {}

.product-card-product-name {
  /* height: 12vh;     */
}

.product-card-product-name h4 {
  font-family: var(---font01);
  font-size: 25px;
  text-transform: capitalize;
}

.Product-card-product-review {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 15px 0px;
}

.Product-card-product-review-star {}

.Product-card-product-review-star svg {
  font-size: 20px;
  margin: 0px 1px;
}

.Product-card-product-review-text {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 5px 0px 0px 10px;
}

.Product-card-product-review-text h5 {
  margin: 0px;
  font-family: var(---font01);
  font-size: 15px;
}

.Product-card-product-review-price {}

.Product-card-product-review-price h6 {
  font-family: var(---font01);
}

.producct-main-card-body-con {
  /* border: 1px solid blue; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.producct-main-card-body {
  /* border: 1px solid red; */
  width: 100%;
}

.clothCard-product-detials-main-con-offer-per{

}

.clothCard-product-detials-main-con-offer-per h4{
  text-align: center;
  font-size: 13px;
  margin: 10px 0px 0px 0px;
  color: red;
  font-weight: 600;
}

@media(max-width:768px) {
  .product-card-product-name h4 {
    font-family: var(---font01);
    font-size: 20px;
  }

  .Product-card-product-review-star svg {
    font-size: 18px;
  }
}

@media(max-width:576px) {
  .product-card-main-con {
    margin: 20px 20px;
  }

  .product-card-Image-con {
    /* border: 1px solid green; */
    height: 80vh;
  }
}

@media(max-width:576px) {
  .product-card-Image-con {
    /* border: 1px solid green; */
    height: 60vh;
  }
}

@media(max-width:768px) {
  /* .main-card-product {
  height: 350px;
  } */
}


@media(max-width:430px) {

  .new-tag h3{
    font-size: 10px;
    background: green;
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
  }

  .new-tag{
    position: absolute;
    right: 6px;
    top: 6px;
  }

  .producct-main-card-body {
    /* border: 1px solid red; */
    width: 100%;
    margin: 0px 5px;
  }

  .main-card-product {
    position: relative;
    background-repeat: no-repeat;
    height: 260px;
  }

  .producct-main-card-body-con {
    justify-content: center !important;
  }

  .clothCard-product-deatils-main-con-price label {
    font-family: var(---font03);
    font-size: 11px;
    letter-spacing: 0px;
  }

  .clothCard-product-deatils-main-con {
    margin: 15px 0px;
    text-align: left;
  }

  .clothCard-product-title {
    font-weight: 400;
    font-family: var(---font03);
    font-size: 12px;
  }

  .clothCard-product-deatils-main-con-price {
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: start;
  }

  .clothCard-product-deatils-main-con-offerprice {
    margin: 0px 0px 0px 10px;
  }
}


@media(max-width:380px) {
  .main-card-product {
    position: relative;
    background-repeat: no-repeat;
    height: 230px;
  }
}

/* ///////////// */