.inner-product-detail {

    width: 103px;
    height: auto;
}

.outer-product-detail {
    margin-bottom: 1rem;
    /* width: 100%; */
    height: auto;
    position: relative;
}

.outer-product-detail img {
    z-index: -1;
    position: relative;

}

.inner-product-detail::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100px;
    height: 100px;
    border: 1px solid red;
    z-index: 10;
    background-color: black;
}

.product-details-price {
    font-weight: 600;
}

.style-no {
    margin-bottom: 5px;
}

.inclusive-taxes {
    font-size: 13px;
    font-weight: 600;
}

.product-detail-size-list {
    padding-right: 10vh;
}

.main-add-to-cart-buy-now {
    display: flex;
    flex-direction: column;
}

.product-detail-add-to-cart {
    border: 2px solid #000;
    border-radius: 8px;
    padding: 5px 0px;
    font-weight: 500;
    margin-bottom: 15px;
}

.product-detail-add-to-cart:hover {
    background-color: #2D3192;
    color: #fff;
    border: 2px solid #2D3192;
}

.product-detail-buy-now {
    background-color: #2D3192;
    color: #fff;
    border-radius: 8px;
    padding: 5px 0px;
    font-weight: 500;
    border: 2px solid #2D3192;

}

.product-detail-buy-now:hover {
    color: #000;
    background-color: white;
    /* color: #fff; */
    border: 2px solid #000;
    border-radius: 1px solid white;
}

.purchase-protection-grid {
    color: gray;
    font-weight: 600;
}

.product-details-faqs-title {
    font-weight: 700;
    margin-bottom: 7px;
    font-size: 15px;

}

.product-details-faqs-para {
    font-size: 14px;
    font-weight: 600;
}

.product-detail-page-product-detail-accordion {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}


.product-detail-page-product-detail-accordion label span {
    font-size: 13px;
    font-family: var(---font01);
    font-weight: 600;
}

.product-detail-chart-size-list {
    /* border: 1px solid red; */
}

.product-detail-chart-size-list h5 {
    font-size: 14px;
}

.recommendaton-section {
    margin: 45px;
}

.similar-product-title {
    font-size: 25px;
}

.selected-product-img {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.product-details-page-product-name-main-con h4 {
    font-family: var(---font03);
    font-size: 20px;
    font-weight: 400;
}

.product-details-page-product-mrp-main-con label {
    font-family: var(---font03);
    font-size: 16px;
    font-weight: 400;
}

.product-details-page-product-mrp-main-con {
    display: flex;
    align-items: center;
}

.product-deatils-main-page-main-price-con {
    margin: 0px 0px 0px 15px;
}

.product-details-page-main-price {
    margin: 8px 0px 0px 0px;
    position: relative;
}

.product-detils-page-points-head{
    padding: 0px 0px 0px 11px;
}


.product-deatils-main-page-main-price-con label::after {
    position: absolute;
    content: "";
    top: 45%;
    left: 0;
    height: 1px;
    width: 100%;
    background: black;
}

.product-deatils-main-page-main-price-con label {
    position: relative;
}

.main-color-list h5 {
    font-family: var(---font03);
    font-size: 18px;
}

.Accordion-details-main-con p {
    font-family: var(---font03);
    font-size: 14px;
    font-weight: 300;
}

.Accordion-details-main-con p a {
    color: var(---colorBlue) !important;
    font-weight: 400;
}

.product-detail-page-facilites-main-con span {
    font-family: var(---font02);
    font-weight: 500;
    font-size: 18px;
}

.similar-product-title {
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
    color: var(---colorBlue);
}

.similar-product-title::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: var(---colorRed);
    width: 100%;
}

.product-deatils-main-page-offer-price-con{

}

.text-start h4{
    text-align: left !important;
    text-align: center;
    font-size: 13px;
    margin: 10px 0px 0px 0px;
    color: red;
    font-weight: 600;
}


.product-deatils-main-page-offer-price-con h4{
    text-align: center;
    font-size: 13px;
    margin: 10px 0px 0px 0px;
    color: red;
    font-weight: 600;
}


.middle-grid-product-detail {
    /* border: 1px solid blue; */
}



/* image magnify css  */
/* Basic styling */
.image-container {
    position: relative;
    display: inline-block;
}

.magnifier {
    position: absolute;
    border: 3px solid #fff;
    border-radius: 50%;
    cursor: none;
    width: 100px;
    /* Diameter of magnifier */
    height: 100px;
    overflow: hidden;
    pointer-events: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.magnifier img {
    position: absolute;
    transform-origin: top left;
    transition: transform 0.1s ease-out;
}

/* end image magnify css  */

.border-danger {
    border: 2px solid black !important;
}


@media (max-width:767px) {
    .product-detail-left-grid {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin: 11px 0px;
    }

    .product-detail-size-list {
        padding-right: unset;
    }
}

.product-detail-page-default-selected-color {
    box-shadow: 0px 0px 3px;
    height: 40px;
    width: 40px;
    border-radius: 100%;
}

.product-detail-page-default-selected-size {
    /* background-color: black;
    color: #fff; */
}

.productmain-des {
    font-weight: 500 !important;
}


.productmain-dispatch {}

@media(max-width:430px) {
    .product-detail-page-product-detail-accordion label span {
        font-size: 12px;
    }

    .product-details-page-points {
        font-size: 12px;
    }
}