.accordion-button:not(.collapsed) {

    background-color:transparent;
}

.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: transparent !important;
    box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion{
    --bs-accordion-border-color: #fff !important;
    --bs-accordion-active-bg: #fff !important;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgb(255,255,255);
}
.accordion-button:focus{
    box-shadow: #fff;
}

.service-para{
    color: #1b1b1b;
    font-weight: 300;
    font-family: var(---font01);
    /* font-weight: 300; */
}
.service-section-1-overlay-section {
    /* border: 1px solid blue; */
    transform: translate(0px, -180px);
  }
  .service-section-svg-overlay-btn-con {
    display: inline-block;
  }
  .service-section-overlay-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    padding: 8px;
    border-radius: 50px;
    background-color: #363636;
  }
  
  .service-section-overlay-btn svg {
    font-size: 20px;
    color: white;
    transition: all 0.4s;
  }
  
  .service-section-overlay-btn:hover svg {
    transform: rotate(-90deg)
  }
  .service-grid-left{
    padding: 84px 80px 94px 0;
  }
  /* .accordion-item{
    border-bottom: 1px solid #e4e4e4;
    border-top: 1px solid #e4e4e4;
  } */
  .accordion-title{
    text-transform: uppercase;
    font-size: 15px;
    font-family: var(---font02);
    font-weight: 500;
  }
  .accordion-button::after {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAANklEQVR4nGNgGGngPxSPWoAT/B8NIkLg/8gOov9UxvS3YOjHATHg/6gFhMD/0SAa8CBiGHQAAOITS7WQXzPpAAAAAElFTkSuQmCC") !important;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIUlEQVR4nGNgGAWjYBSMApLBfyrhgbNgFIyCUTAKGDABAOkcI91xODUvAAAAAElFTkSuQmCC") !important;
  
    /* transform: rotate(90deg); */
  }
  .faq-title{
    color: #272b65;
    font-size: 45px;
    line-height: 51px;
    font-weight: 400;
    /* font-family: Muli, Arial, sans-serif; */
    text-align: center;
    font-weight: 300;
    letter-spacing: 0;
    margin: 0;
    padding: 10px 0 20px;
  }
  .faq-img{
    margin: auto;
    display: block;
    margin-top: 37px;
  }
  .faq-ans-para{
    font-size: 14px;
  }
  .main-div{
    position: sticky;
    top: 70px;
  }