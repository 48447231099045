.SuccessPage-Section-con {
    /* border: 1px solid red; */
    padding: 10vh 0px;
}

.SuccessPage-Section {
    /* height: 100vh; */
    /* border: 1px solid #f1f1f1; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(40deg, antiquewhite, #FF7F7F); */
    /* background: linear-gradient(40deg, rgb(38, 51, 215, 3), #EC0411); */
}

.SuccessPage-Section-Details-con {
    width: 50%;
    padding: 20px 0px;
}

.order-Success-con {}

.order-Success-con-icons {
    border: 1px solid #f1f1f1;
    padding: 20px;
    background-color: #fff;

}

.order-Success-con-icons svg {
    font-size: 40px;
    color: #05a485;
}

.order-Success-con-icons h2 {
    font-size: 30px;
    font-family: var(---font02);
    margin: 15px 0px 10px 0px;
}

.order-Success-con-icons p {
    font-size: 14px;
}


/* ///////////// */

.Order-Success-Details-con-Main {
    border: 1px solid;
    border: 1px solid #f1f1f1;
    padding: 20px;
    margin: 30px 0px 0px 0px;
    background-color: #fff;
}

.Order-Success-Details-con {
    display: flex;
}

.Order-Success-Details-add {
    /* border: 1px solid red; */
    text-align: left;
}

.Order-Success-Details-Image-con {
    height: 13vh;
    width: 25vh;
}

.Order-Success-Details-Image-con img {
    height: 100%;
    width: 100%;
}

.Order-Success-Details-add h1 {
    font-size: 13px;
    text-transform: capitalize;
}

.Order-Success-Details-name-contact {
    display: flex;

}

.Order-Success-Details-name-contact h2 {
    font-size: 14px;
    font-family: var(---font01);
    font-weight: 600;
    margin: 0px;
}

.Order-Success-Details-name-contact span {
    font-size: 12px;
    font-family: var(---font01);
    font-weight: 600;
    margin: 0px 5px;
}

.Order-Success-Details-address {}

.Order-Success-Details-address p {
    font-size: 12px;
    margin: 5px 0px 0px 0px;
}

.SuccessPageprice {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Order-Success-List-con {
    margin: 30px 0px 30px 0px;
    border: 1px solid #f1f1f1;
    padding: 20px;
    background-color: #fff;
}


.Order-Success-List-head {
    /* border: 1px solid red; */
    margin: 0px 0px 10px 0px;
}

.Order-Success-List-head h3 {
    text-align: left;
    margin: 0px;
    font-size: 13px;
    text-transform: capitalize;
    font-family: var(---font01);
    font-weight: 700;
}

.Order-Success-List-head h3 span {
    font-size: 13px;
    font-weight: 100;
}

.Order-Success-Details-con-Main-head {
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
}

.order-success-page-btn-con {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-success-page-btn-con a {}

.order-success-page-btn-con a button {
    border: none;
    font-size: 13px;
    background: var(--primaryColor);
    color: white;
    padding: 6px 15px;
    /* border-radius: 30px; */
}

.Successpage-Product-addedCart-Side-Bar {
    /* border: 1px solid red; */
    padding: 10px;
    height: 36vh;
    overflow-y: scroll;
}


/*
 *  STYLE 4
 */

#style-4::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #555555;
}


@media(max-width:767px){
    .order-success-page-btn-con a button{
        font-size: 11px !important;
        background: var(--primaryColor);
        color: white;
        padding: 7px 8px !important;
    }
    .SuccessPage-Section-Details-con {
        padding: 20px 0;
        width: 100%;
    }
}

