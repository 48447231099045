/* /////////////////////// section 1 ////////////////////////// */

.AboutUs-Section-1 {
    /* border: 1px solid blue; */
    padding: 10vh;
}

.AboutUs-Section-1-con {
    /* border: 1px solid red; */
}

.About-Section-1-Image-con {
    /* border: 1px solid gold; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.About-Section-1-Image {
    /* border: 1px solid black; */
    height: 60vh;
    /* width: 60vh; */
    /* border: 5px solid var(---colorBlue); */
}

.About-Section-1-Image img {
    height: 100%;
    width: 100%;
}


.About-Section-1-Details-con {
    text-align: left;
}

.About-Section-1-Details-con h2{
    font-family: var(---font03);
    font-size: 35px;
    /* color: var(---colorBlue); */
    font-weight: 400;
}

.About-Section-1-Details-con h4 {
    font-size: 20px;
    margin-bottom: 22px;
    font-family: var(---font01);
    color: #777777;
}

.About-Section-1-Details {
    display: flex;
    align-items: center;
    height: 100%;
}

.About-Section-1-Details-con p {
    font-size: 15px;
    text-align: left !important;
    font-family: var(---font01);
}

/* /////////////////////// section 2 ////////////////////////// */

.AboutUs-Section-2 {
    /* border: 1px solid red; */
    padding: 10vh 0px;
}

.AboutUs-Section-Counter {
    /* border: 1px solid blue; */
}

.AboutUs-Section-Counter-card {
    display: flex;
    padding: 0px 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e1e1e1;
}

.AboutUs-Section-Counter-Image-con {
    /* border: 1px solid firebrick; */
}

.AboutUs-Section-Counter-Image {
    /* border: 1px solid black; */
    height: 40px;
    width: 40px;
}

.AboutUs-Section-Counter-Image img {
    height: 100%;
    width: 100%;
}

.AboutUs-Section-Counter-card-details {
    /* border: 1px solid blue; */
    text-align: left;
    margin: 0px 0px 0px 20px;
}

.AboutUs-Section-Counter-card-details h2 {
    font-weight: 500;
    font-size: 35px;
    font-family: var(---font01);
    line-height: 1.2;
    transition: color .3s 0s ease-out;
}

.AboutUs-Section-Counter-card-details h3 {
    font-family: var(---font02);
    /* letter-spacing: 2px; */
    margin: 0px 0px 5px 0px;
    /* color: #821F40; */
    font-weight: 400;
    font-size: 18px;
    position: relative;
    z-index: 1;
    display: inline-block;
}

  /* start new design */
  .about-us-new-Section-1 {
    /* border: 1px solid red; */
    height: 40vh;
    background: linear-gradient(90deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url("../Assert/Banner-4.jpg");
    -webkit-background-size: cover;
    /* background: linear-gradient(40deg, rgb(220, 214, 209), rgb(204, 179, 149)); */
}
.about-us-new-Section-con {
    /* border: 1px solid blue; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 40vh;
}
.about-us-new-Section-Text {
    margin: 0px 0px 0px 30px;
}
.about-us-new-Section-Text h2 {
    text-align: left;
    text-transform: capitalize;
    font-family: var(---MainHeadFont);
    font-weight: 500;
    font-size: 30px;
    letter-spacing: 1px;
    color: white;
}
.about-us-new-Section-NavPage {
    display: flex;
    align-items: center;
    font-family: var(---SmallFont);
}
.about-us-new-Section-NavPage span {
    font-family: var(---Font1);
    color: white;
}

.about-us-new-Section-NavPage span svg {
    font-size: 17px;
    margin: 0px 4px 5px 0px;
}

.about-us-new-Section-NavPage h3 {}

.about-us-new-Section-NavPage h3 svg {
    color: white;
}

.about-us-new-Section-NavPage h2 {
    font-size: 16px;
    margin: 0px;
    font-family: var(---Font1);
    font-weight: normal;
    color: white;

}


@media(max-width:768px) {
    .About-Section-1-Details-con {
        margin-top: 25px;
        text-align: left;
    }

    .AboutUs-Section-2 {
        /* border: 1px solid red; */
        padding: 5vh 0px 5vh 0px;
    }

}

@media(max-width:576px) {
    .AboutUs-Section-Counter-card {
        justify-content: space-around !important;
        align-items: center;
    }

    .AboutUs-Section-Counter-card-details {
        width: 60%;
    }

    .AboutUs-Section-1 {
        /* border: 1px solid blue; */
        padding: 10vh 0px;
    }

    .About-Section-1-Image-con {
        /* border: 1px solid gold; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .AboutUs-Section-Counter-Image {
        /* border: 1px solid black; */
        height: 40px;
        width: 40px;
    }

    .AboutUs-Section-Counter-card-details h2 {
        margin: 0px;
        font-size: 16px;
    }
    .AboutUs-Section-Counter-card-details h3 {
        font-family: var(---font02);
        margin: 0px 0px 5px 0px;
        font-weight: 400;
        font-size: 14px;
    }

    .About-Section-1-Image {
        /* border: 1px solid black; */
        height: auto;
        width: 100%;
    }

    .About-Section-1-Details-con p {
        font-size: 12px;
        text-align: left !important;
        font-family: var(---font01);
    }

    .About-Section-1-Details-con h2 {
        font-family: var(---font03);
        font-size: 25px;
        /* color: var(---colorBlue); */
        font-weight: 400;
    }

    .about-us-new-Section-Text h2 {
        font-size: 22px;
    }

    .about-us-new-Section-NavPage span {
        font-family: var(---Font1);
        color: white;
        font-size: 12px;
    }

    .about-us-new-Section-NavPage h2{
        font-size: 12px !important;
    }

    .about-us-new-Section-NavPage h3 svg {
        color: white;
        font-size: 18px;
        margin: 7px 0px 0px 0px;
    }
  }


  @media (max-width: 430px) {
    .About-Section-1-Image {
        /* border: 1px solid black; */
        height: auto;
        width: 70%;
    }
}


.home-section-about-image-main-con {
    /* border: 1px solid green; */
    position: relative;
}

.home-section-about-image-first-main-con {
    /* border: 1px solid blue; */
    height: fit-content;
}

.home-section-about-image-first-main-con img {
    height: 100%;
    width: 100%;
    border-radius: 30px;
    box-shadow: 0 0 5px #b5b5b5;
}

.home-section-about-image-second-main-con {
    position: absolute;
    height: 40vh;
    width: fit-content;
    bottom: 10%;
    right: 0px;
    border: 5px solid whitesmoke;

}

.home-section-about-image-second-main-con img {
    height: 100%;
    width: auto;
}
.home-section-about-image-main-con-main{
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-section-about-image-main-con .working-area {
    background: #3b72fc;
    display: inline-block;
    padding: 25px 16px 10px;
    text-align: center;
    position: absolute;
    top: 86px;
    left: -50px;
}

.working-area .inner {
    position: relative;
}

.home-section-about-image-main-con .inner:before {
    content: "";
    position: absolute;
    bottom: -56px;
    left: -16px;
    width: 50px;
    height: 30px;
    background: #3c72fc;
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 48% 47%, 0 0);
}

.home-section-about-image-main-con .icon-box i {
    color: #fff;
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-section-about-image-main-con .icon-box h4 {
    color: #fff;
    margin-top: 14px;
    margin-bottom: 1px;
}

.home-section-about-image-main-con .icon-box p {
    font-family: var(--secondary-font);
    color: #fff;
    position: relative;
}

.float-bob-y {
    -webkit-animation-name: float-bob-y;
    animation-name: float-bob-y;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}



@keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
        -ms-transform: translateX(2000px);
        transform: translateX(2000px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.fadeInRightBig {
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@media(max-width:576px){
    .home-section-about-image-first-main-con{
        height: 100%;
    }
    .home-section-about-image-main-con {
        width: 100%;
    }
    .home-section-about-image-second-main-con {
        position: absolute;
        height: 30vh;
        width: -moz-fit-content;
        width: fit-content;
        bottom: 10%;
        right: 0px;
        border: 5px solid whitesmoke;
    }
    .home-section-about-image-first-main-con {
        /* border: 1px solid blue; */
        height: 100%;
        width: 100%;
    }
    .home-section-about-image-main-con .icon-box i {
        color: #fff;
        font-size: 35px;
    }
    .home-section-about-image-main-con .icon-box h4 {
        color: #fff;
        margin-top: 6px;
        margin-bottom: 1px;
        font-size: 14px;
    }
    .home-section-about-image-main-con .icon-box p {
        font-size: 12px;
    }
    .home-section-about-image-main-con .working-area {
        background: #3b72fc;
        display: inline-block;
        padding: 10px 10px 10px;
    }
    .home-section-about-image-main-con .inner:before {
        content: "";
        position: absolute;
        bottom: -56px;
        left: -9px;
        width: 50px;
    }
}

@media (max-width: 430px) {
    .home-section-about-image-main-con {
        /* width: 75%; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .home-section-about-image-first-main-con {
        /* border: 1px solid blue; */
        height: fit-content;
        width: 100%;
    }
    .home-section-about-image-second-main-con {
        display: none;
    }
}