.audio{
position: fixed;
bottom: 3%;
left: 3%;
z-index: 1000;
}


.audio button{
    border: 1px solid black ;
    padding: 10px;
    background-color: black;
    border-radius: 50px;
}

.audio button svg{
    font-size: 25px;
    color: white;
}