.privacy-policy-section{
}


.privacy-policy-section h1{
    text-align: left;
    font-family: var(---font03);
    font-size: 20px;
}

.privacy-policy-section h2{
    text-align: left;
    font-family: var(---font03);
    font-size: 20px;
}

.privacy-policy-section h3{
    text-align: left;
    font-family: var(---font03);
    font-size: 20px;
}

.privacy-policy-section thead tr th{
    text-align: left;
    font-family: var(---font03);
    font-size: 20px;
    font-weight: 400;
}

.privacy-heading{
    text-align: left;
    font-family: var(---font03);
    font-size: 20px;
    font-weight: 400;
}

.privacy-policy-section-details{

}

.privacy-policy-section-details{
    text-align: left;
}

.privacy-policy-section-details h1{

}

.privacy-policy-section-details p{
    font-family: var(---font03);
    font-size: 12px;
}

.privacy-policy-section ul li{
    font-family: var(---font03);
    font-size: 12px;
    margin-bottom: 10px;
}

.privacyli{
    font-family: var(---font03);
    font-size: 12px;
    margin-bottom: 10px;
}

.privacy-policy-section tbody tr td{
    font-family: var(---font03);
    font-size: 12px;
    margin-bottom: 10px;
}