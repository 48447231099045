.footer-list{
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}
.social-media-icon{
    display: flex;
    gap: 17px;
    font-size: 27px;
}
.social-media-icon label a{
    color: unset !important;
}
.inner-return-policy-div{
    display: flex;
    gap: 22px;
    font-size: 14px;
}
.inner-return-policy-div img{
    height: 30px;
}
.all-rights-reserved{
    display: flex;
    justify-content: space-between;
}
.ship-all-over-india{
    font-size: 14px;
}
.main-return-policy-div div{
    padding-bottom: calc(var(--bs-gutter-x)* .5);
}
.footer-list label{
    cursor: pointer;
    cursor: pointer;
    font-family: var(---font01);
    color: rgb(46,46,46);
}
.footer-design-by-skdm{
    font-size: 13px;
}

.page-footer{

}

.page-footer{
    
}

.footer-links{
    /* border: 1px solid red; */
    margin-bottom: 20px;
}

.page-footer h6{
    font-family: var(---font03);
    font-size: 20px;
    margin: 0px 0px 12px 0px;
    text-transform: uppercase;
    font-weight: 400;
}

.footer-list a{
    font-size: 13px;
    margin: 0px 0px 11px 0px;
    font-family: var(---font03);
}

.footer-map-main-con{
    /* border: 1px solid blue; */
    height: 30vh;
}

.footer-design-by-skdm{
    font-size: 10px;
}

.all-rights-reserved p{
    font-size: 10px;
}

.footer-pat-icons-main-con{
    width: 70%;
}

.footer-pat-icons-con{
    /* border: 1px solid red; */
    display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        max-height: 25px;
        width: 100%;
        max-width: max-content;
        margin-bottom: 10px;
}

.footer-pat-icons-con img{
    height: 100%;
    width: 100%;
    max-width: max-content;
    max-height: 40px;
}
@media(max-width:576px){
    .footer-design-by-skdm {
        font-size: 8px;
    }

    .footer-map-main-con {
        margin-bottom: 30px;
    }

}