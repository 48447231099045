.home-section-wedding {
    /* border: 1px solid red; */
    padding: 10vh 0px;
    background-color: #faf5f2;
}

.home-section-wedding-main-con {
    padding: 40px 0px;
}

.home-section-wedding-main-head-con {
    text-align: center;
}

.home-section-wedding-main-head-con h2 {
    font-family: var(---font03);
    font-size: 35px;
    color: var(---colorBlue);
}

.home-section-wedding-main-head-con p {
    font-size: 17px;
    font-family: var(---font01);
}

.home-section-wedding-first-main-image-con {
    /* border: 1px solid blue; */
}

.home-section-wedding-first-main-image {
    /* border: 1px solid red; */
    height: auto;
}

.home-section-wedding-first-main-image img {
    height: 100%;
    width: 100%;
}

.home-section-wedding-main-details-main-con {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}


.home-section-wedding-first-main-image-left {
    /* transform: rotate(-10deg); */
}

.home-section-wedding-first-main-image-right {
    /* transform: rotate(10deg); */
}

.home-section-wedding-main-details{

}

.home-section-wedding-main-details p{
    font-family: var(---font03);
    font-size: 15px;
}

@media(max-width:768px) {
    .home-section-wedding-main-details-main-con {
        margin: 0px 40px;
    }
}

@media(max-width:576px) {
    .home-section-wedding-first-main-image {
        /* border: 1px solid red; */
        height: auto;
        width: 60%;
    }

    .home-section-wedding-main-details p{
        font-family: var(---font03);
        font-size: 12px;
    }

    .home-section-wedding-first-main-image-con {
        /* border: 1px solid blue; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .home-section-wedding-main-details-main-con {
        margin: 0px 20px;
    }

    .home-wedding-images-main-con {
        display: none;
    }

    .home-section-wedding-main-con {
        padding: 0px;
    }

    .home-section-wedding {
        padding: 5vh 0px 5vh 0px;
    }
}