.recommendation-product-name{
    text-align: left !important;
    font-weight: 400;
    line-height: 18px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: var(---font02);
}


.product-price-regular {
position: relative;
}


.clothCard-product-deatils-main-con-offerprice label::after {
    position: absolute;
    content: "";
    top: 45%;
    left: 0;
    height: 1px;
    width: 100%;
    background: black;
}