.home-section-new-home-category {
    /* border: 1px solid red; */
    padding: 10vh 0px;
    /* linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)),  */
}

.home-section-new-home-details-con-main {}

.home-section-first-main-new-category-con {
    /* border: 1px solid blue; */
}



.home-section-first-main-new-category-con-image-con {
    /* border: 1px solid green; */
    height: 90vh;
    position: relative;
    background: url("../Assert/home-cat/about-image-1.jpg");
    background-size: 100% 100%;
}

.home-section-first-main-new-category-con-image-details-con {
    /* border: 1px solid darkblue; */
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
}

.home-section-first-main-new-category-con-image-details-con {
    text-align: right;
    padding: 0px 30px;
}

.home-section-second-main-new-category-details {}

.home-section-first-main-new-category-con-image-details-con h3 {
    font-family: var(---font03);
    font-size: 33px;
    font-weight: 600;
    color: var(---colorBlue);
    letter-spacing: 2px;
    margin: 0px 0px 0px 0px;
}

.home-section-first-main-new-category-con-image-details-con h4 {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 300;
}

.home-section-first-main-new-category-con-image-details-btn-con {
    margin: 20px 0px 0px 0px;
}

.home-section-first-main-new-category-con-image-details-btn-con button {
    font-size: 12px;
    padding: 10px 0px 0px 0px;
    border-top: 2px solid var(---colorRed);
    color: white;
}

.home-section-second-main-new-category-con {
    /* border: 1px solid red; */
    height: 100%;
}

.home-section-second-main-new-category-main-con {
    /* border: 1px solid blue; */
    position: relative;
    border-radius: 10px;
    box-shadow: 0 0 5px gray;
}

.home-section-second-main-new-category-details {
    /* border: 1px solid red; */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(160deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, .3));
    display: flex;
    align-items: end;
    border-radius: 10px;

}



.home-section-second-main-new-category-details-con {
    /* border: 1px solid blue; */
    padding: 10px 20px;
    width: 100%;

}

.home-section-second-main-new-category-main-con h2 {
    font-family: var(---stylefont);
    /* font-weight: bolder; */
    font-size: 45px;
    letter-spacing: 1px;
    margin: 0px;
    color: white;
}


.home-section-second-main-new-category-main-con h3 {
    font-family: var(---font03);
    font-size: 17px;
    font-weight: 400;
    color: white;
    letter-spacing: 0px;
    text-transform: uppercase;
}

.home-section-second-main-new-category-main-con h3 span {}

.home-section-second-main-new-category-image-con {
    /* border: 1px solid black; */
    height: auto;
    overflow: hidden;
    border-radius: 10px;
}

.home-section-second-main-new-category-main-con-transform {
    /* display: flex; */
    /* justify-content: end; */
    height: 100%;
    /* border: 1px solid blue; */
    transform: translate(0px, 16%);
}

.home-section-second-main-new-category-image-con img {
    height: 100%;
    width: 100%;
    transition: scale 8s cubic-bezier(.25, .46, .45, .94);
    transition: all .5s ease-in-out;
    border-radius: 10px;
}

.home-section-second-main-new-category-image-con:hover img {
    border: 3px solid black;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.home-section-head-main-con {
    padding: 40px 0px;
}

.home-section-head-main-con h2 {
    text-align: center;
    font-family: var(---font03);
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 400;
    margin-bottom: 20px;
}

.home-section-head-main-con h3 {
    text-align: center;
    font-family: var(---font03);
    font-size: 30px;
    letter-spacing: 3px;
    font-weight: 400;
    margin-bottom: 20px;
}

.ant-modal {
    /* display: flex !important; */
    justify-content: center !important;
}


.popup-offer {
    max-height: 500px;
}


.prodcut-head-name{
    text-transform: uppercase;
    margin: 0px 0px 20px 0px;
}

@media(max-width:1024px) {
    .home-section-first-main-new-category-con-image-con {
        /* border: 1px solid green; */
        height: 70vh;
    }

    .home-section-second-main-new-category-main-con-transform {
        /* display: flex; */
        /* justify-content: end; */
        height: 100%;
        /* border: 1px solid blue; */
        transform: translate(0px, 0%);
    }
}

@media (max-width: 768px) {
    .home-section-first-main-new-category-con-image-con {
        /* border: 1px solid green; */
        height: 80vh;
    }

    .home-section-second-main-new-category-main-con h3 {
        font-family: var(---font03);
        font-size: 17px;
    }

    .home-section-second-main-new-category-con {
        /* border: 1px solid red; */
        height: 100%;
        margin: 30px 0px 0px 0px;
    }

    .home-section-second-main-new-category-main-con h2 {
        font-family: var(---stylefont);
        font-size: 32px;
    }

    .popup-offer {
        max-height: 300px !important;
    }
}

@media(max-width:768px) {
    .home-section-second-main-new-category-main-con {
        margin-bottom: 30px;
    }

    .Home-page-offer-page-con {
        height: 45vh !important;
    }


    .popup-offer {
        max-height: 300px !important;
    }
}


@media(max-width:576px) {
    .home-section-head-main-con h3 {
        text-align: center;
        font-family: var(---font03);
        font-size: 22px;
        letter-spacing: 2px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .home-section-head-main-con {
        padding: 20px 0px;
    }

    .home-section-second-main-new-category-main-con h3 {
        font-family: var(---font03);
        font-size: 14px;
    }

    .home-section-second-main-new-category-main-con h2 {
        font-family: var(---stylefont);
        font-size: 26px;
    }

    .Home-page-offer-page-con {
        height: 30vh !important;
    }

    .home-section-section-main-con-category-video-con {
        /* border: 1px solid blue; */
        height: max-content !important;
    }

.popup-offer{
    max-height: 200px !important;
}
}


@media(max-width:430px) {
    .home-section-new-home-category {
        /* border: 1px solid red; */
        padding: 0px;
        /* linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)),  */
    }

    .home-section-head-main-con h2,
    .home-section-head-main-con h3 {
        font-family: var(---font03);
        font-weight: 400;
        margin-bottom: 10px;
    }

    .home-section-head-main-con {
        padding: 20px 0px 0px 0px;
    }
}

.home-section-section-main-con-category-video-main-con {
    /* border: 1px solid red; */
}

.home-section-section-main-con-category-video-con {
    /* border: 1px solid blue; */
    height: 65vh;
    /* background-color: whitesmoke; */
    border-radius: 7px;
}

.home-section-section-main-con-category-video-con video {
    height: 100%;
    width: 100%;
}



/* offer  */

.Home-page-offer-page-main-con {
    display: flex;
    justify-content: center;
    align-items: center;
}


.Home-page-offer-page-con {
    height: 60vh;
    width: max-content;
}

.Home-page-offer-page-con img {
    height: 100%;
    width: 100%;
}

.custom-modal .ant-modal-content {
    width: max-content;
}